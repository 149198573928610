import axios, { AxiosRequestConfig } from 'axios';
import { AuthStore } from '../stores/authStore';
import {
	LoginOptions,
	Admin,
	UsersResponse,
	LeaderboardResponse,
	LeaderboardWithFilteringResponse,
	LeaderboardRequest,
	PutResponse,
	ResetResponse,
	UserResponse,
	GetTransactionEventsResponse,
	GetUserTransactionsResponse,
	GetAppSettingsResponse,
	AppSettings,
	StateResponse,
	NotificationBroadcast,
	CSVFilesResponse,
	ProjectCollectablesResponse,
	AdminsResponse,
	PaymentsSingleUserResponse,
	WaypointsResponse,
	CountriesResponse,
	TagsResponse,
	VehiclesResponse,
	UserListResponse,
	UsersForSelectResponse,
	UploadsResponse,
	ProjectCollectablesSummaryResponse,
	ObjectsResponse,
	PaymentsByUserIdParamsI,
	LoginResponse,
	GetAllUserFilters,
	GetProjectWaypointsResponse
} from '../models/general';
import { GetPhotoSessionDataResponse, SessionResponse } from '../models/sessions';
import { SingleUploadResponse, CollectablesSingleRequest, UploadsParams, VideoRequest, VideoResponse, LastPendingUploadI } from '../models/video';
import { PaymentResponse, PaymentsParams } from '../models/payment';
import {
	SingleProjectResponse,
	ProjectsResponse,
	ProjectRequest,
	AddProject,
	EditProject,
	AddPhotoProject,
	PhotoExportMarkersI,
	EditPhotoProject
} from '../models/projects';
import { UsersParams } from '../models/user';
import { Project } from '../stores/projectEditorStore';
import { Transaction, TransactionEvent } from '../../../legacy/src/types/transactions';

const getHeaders = (language: string) => {
	return { 'Accept-Language': `${language}, *;0.9` };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseParams = (params: { [x: string]: any[] }) => {
	const keys = Object.keys(params);
	let options = '';

	keys.forEach(key => {
		const isParamTypeObject = typeof params[key] === 'object';
		const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

		if (!isParamTypeObject) {
			options += `${key}=${params[key]}&`;
		}

		if (isParamTypeObject && isParamTypeArray) {
			params[key].forEach(element => {
				options += `${key}=${element}&`;
			});
		}
	});

	return options ? options.slice(0, -1) : options;
};

export interface API {
	addAdmin(firstName: string, lastName: string, username: string, email: string): Promise<any>;
	createPhotoProject(data: AddPhotoProject): Promise<any>;
	changeInvasiveSessionStatus: (
		sessionUploadIds: number[],
		status: 'approve' | 'reject' | 'rejectWithPayment',
		rejectionReasonId?: number,
		customRejectionReason?: string,
		changedPaymentAmount?: number,
		sessionId?: number
	) => Promise<any>;
	createProject(data: AddProject): Promise<any>;
	deleteAdmins(adminIds: number[]): Promise<any>;
	deleteAllCollectables(): Promise<any>;
	deleteCollectables(csvFileIDs: number[]): Promise<any>;
	deleteProject(projectId: number): Promise<any>;
	disableSightingPolygon(initialSessionId: number): Promise<any>;
	deleteSelectedPaymentsRows(paymentIds: number[]): Promise<any>;
	deleteUncollectedObjects(projectId: number): Promise<any>;
	deleteUsers(userIds: number[]): Promise<any>;
	editPhotoProject(data: EditPhotoProject, projectId: number): Promise<any>;
	editProject(data: EditProject, projectId: number): Promise<any>;
	exportInvasiveUploads(projectId: number): Promise<{ data: string }>;
	getAppSettings(): Promise<GetAppSettingsResponse>;
	testSessionValue(projectId: number, density: number, marker: number, area: number): Promise<any>;
	getAdminInfo(): Promise<Admin>;
	getAdminsList(): Promise<AdminsResponse>;
	getCollectablesList(projectId: number | null): Promise<CSVFilesResponse>;
	getAllUsers: (filters: GetAllUserFilters) => Promise<any>;
	getCountries(): Promise<CountriesResponse>;
	getExportedCoordinates(photoIDs: number[]): Promise<any>;
	getExportedPhotos(photoIDs: number[]): Promise<any>;
	getExportedVideo(videoID: number): Promise<any>;
	getAllSessions(): Promise<SessionResponse>;
	getExportedVideos(videoIDs: number[]): Promise<any>;
	getGeojson(videoIDs: number[]): Promise<any>;
	getGPX(videoID: number): Promise<any>;
	getLastPendingUpload(userId: number): Promise<LastPendingUploadI>;
	getLastUploaded(userId: number): Promise<LastPendingUploadI>;
	getLeaderboard(): Promise<LeaderboardResponse>;
	getLeaderboardPerProjectAndCountry(selectedProjectAndCountry?: LeaderboardRequest): Promise<LeaderboardWithFilteringResponse>;
	getObjects(): Promise<ObjectsResponse>;
	getPayments(paymentsParams?: PaymentsParams): Promise<PaymentResponse>;
	getPaymentsByUserId(params: PaymentsByUserIdParamsI): Promise<any>;
	getPaymentsCSV(paymentIds: number[]): Promise<any>;
	getUserPayments(startMonth: number, endMonth: number, projectId: number | null): Promise<PaymentResponse>;
	getInvasiveSpeciesDeviceMetadata(sessionId: number): Promise<any>;
	getInvasiveSessionFromSightingId(sightingId: number): Promise<any>;
	getPhotoExportMarkers(params: PhotoExportMarkersI): Promise<any>;
	getUserFeedbackSubmissions(params: any): Promise<any>;
	getProject(projectId: number, language: string): Promise<SingleProjectResponse>;
	getProjectCollectables(projectId: number): Promise<ProjectCollectablesResponse>;
	getProjectCollectablesSummary(projectId: number): Promise<ProjectCollectablesSummaryResponse>;
	getProjectList(projectRequest?: ProjectRequest): Promise<ProjectsResponse>;
	getProjectWaypoints(projectId: number): Promise<GetProjectWaypointsResponse>;
	getRejectionReasons(): Promise<any>;
	getSingleUserPayments(ID: number, startDate: number, endDate: number, projectId: number | null): Promise<PaymentsSingleUserResponse>;
	getStateList(): Promise<StateResponse>;
	getTags(): Promise<TagsResponse>;
	getTxt(objectID: number): Promise<any>;
	getUpload(uploadId?: number | string): Promise<SingleUploadResponse>;
	getPhotoSessionData(sessionId: number): Promise<GetPhotoSessionDataResponse>;
	getUploadCollectables(videoId: number | string): Promise<CollectablesSingleRequest>;
	getUploads(uploadsParams: UploadsParams): Promise<UploadsResponse>;
	getUploadsById(projectID: number | string): Promise<any>;
	getInvasiveSpeciesSessionData(sessionId: number): Promise<any>;
	getUser(userId: number): Promise<UserResponse>;
	getUserList(page: number, limit: number, country?: string, mission?: string, searchedValue?: string): Promise<UserListResponse>;
	getUserSessions(userId?: number): Promise<SessionResponse>;
	getUserVideos(userId?: number): Promise<any>;
	getUsers(selectedProject?: number | null): Promise<UsersResponse>;
	getUsersCSV(userIDs: number[]): Promise<any>;
	getUsersForSelect(usersParams?: UsersParams): Promise<UsersForSelectResponse>;
	getUserTransactions(userId: number): Promise<GetUserTransactionsResponse>;
	getTransactionEvents(transactionId: number, userId: number): Promise<GetTransactionEventsResponse>;
	getVehicles(): Promise<VehiclesResponse>;
	getVideos(videoRequest: VideoRequest): Promise<VideoResponse>;
	getVideoFile(videoURL: string): Promise<Blob>;
	getWaypoints({
		projectID,
		videoIDs,
		offset,
		limit,
		startDate,
		endDate,
		createdAt,
		status
	}: {
		videoIDs: number[];
		projectID?: string;
		offset?: number;
		limit?: number;
		startDate?: number;
		endDate?: number;
		createdAt?: Date;
		status: number | undefined;
	}): Promise<WaypointsResponse>;
	getWaypointsForProject(projectID: number, startDate?: number, endDate?: number): Promise<WaypointsResponse>;
	login(email: string, password: string, options: LoginOptions): Promise<void>;
	loadProject(projectId: number): Promise<any>;
	modifyUserBalance(amount: number, info: string, userId: number): Promise<any>;
	patchFinishProjectStatus(projectId: number): Promise<any>;
	pauseProject(projectId: number): Promise<void>;
	patchMarkAsPaid(paymentIds: number[]): Promise<any>;
	patchUploads(id: number, status: 'approve' | 'reject' | 'rejectWithPayment', bodyParams?: Record<string, string | number>): Promise<any>; // to-do fix this any type
	resetPassword(token: string, password: string): Promise<ResetResponse>;
	resetPasswordRequest(email: string): Promise<ResetResponse>;
	getUploadSummary(id: number): Promise<any>;
	resumeProject(projectId: number): Promise<void>;
	returnObjectsToMap(uploadId: number): Promise<any>;
	saveAppSettings(appSettings: AppSettings): Promise<any>;
	saveCustomProject(data: any): Promise<any>;
	saveProject(project: Project): Promise<any>;
	sendNotifications(data: NotificationBroadcast): Promise<any>;
	setSuperAdmin(userIds: number[]): Promise<any>;
	setUserPayments(userIds: number[], startDate: number, endDate: number): Promise<PutResponse>;
	syncAllCollectables(): Promise<void>;
	syncAllPhotoMarkers(): Promise<void>;
	syncCollectables(projectID: number): Promise<void>;
	syncMarkers(): Promise<void>;
	syncPhotoMarkers(projectID: number): Promise<void>;
	uploadCollectables(file: any, projectID: number): Promise<PutResponse>;
	uploadGeojson(file: any): Promise<any>;
	uploadVideosToVendor(videoIDs: number[]): Promise<any>;
	updateSessionDensity(sessionId: number, density: number, area: number, marker: number, projectId: number): Promise<any>;
	updateSessionArea(sessionId: number, density: number, area: number, marker: number, projectId: number): Promise<any>;
	validateVideo(validated: boolean, videoID: number, message: string): Promise<PutResponse>;
}

export function createAPI(authStore: AuthStore): API {
	const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

	function tokenInterceptor(config: AxiosRequestConfig) {
		const token = authStore.token;
		if (!!token && token !== undefined && config !== undefined) {
			// @ts-ignore-next-line
			config.headers['jwt'] = `${token}`;
		}
		return config;
	}

	instance.interceptors.request.use(tokenInterceptor);

	instance.interceptors.response.use(
		response => {
			return response;
		},
		function (error) {
			if (error.response.status === 400) {
				return Promise.reject(error.response.data);
			}
			return Promise.reject(error.response);
		}
	);

	function handleLoginResponse(response: LoginResponse, options: LoginOptions = { rememberMe: false }) {
		const { token, user } = response.data;
		authStore.setToken(token, options);
		authStore.setAdminRole(user.role, options);
		authStore.setGlobalProject(user.projectID || undefined, options);
	}

	return {
		async login(email, password, options) {
			const res = await instance.post('/auth/signin', { email, password }, { params: {} });
			handleLoginResponse(res.data, options);
		},

		async getAdminInfo() {
			const res = await instance.get('/auth/me', {
				params: {}
			});
			return res.data;
		},

		async getAdminsList() {
			const res = await instance.get('users/admins/all');
			return res.data;
		},

		async deleteAdmins(adminIds: number[]) {
			const res = await instance.delete('/users/admins', {
				data: {
					IDs: adminIds
				}
			});
			return res.data;
		},

		async setSuperAdmin(userIds: number[]) {
			const res = await instance.patch('/users/admins', {
				userIds
			});

			return res.data;
		},

		async addAdmin(firstName, lastName, username, email) {
			const res = await instance.post('users/admins', { firstName, lastName, username, email });
			return res.data;
		},

		async getInvasiveSpeciesDeviceMetadata(sessionId: number) {
			const res = await instance.get(`/invasive/get-device-data/${sessionId}`);
			return res.data;
		},

		async getInvasiveSessionFromSightingId(sightingId: number) {
			const res = await instance.get(`/invasive/get-sessions-from-sighting-id/${sightingId}`);
			return res.data;
		},

		async resetPassword(token, password) {
			const res = await instance.put(`/users/password/${token}`, { password }, { params: {} });
			return res.data;
		},

		async resetPasswordRequest(email) {
			const res = await instance.post('/users/forgot-password', { email }, { params: {} });
			return res.data;
		},

		async getUser(userId: number) {
			const res = await instance.get(`/users/${userId}/`, {
				params: {}
			});
			return res.data;
		},

		async getUserList(page: number, limit: number, country?: string, mission?: string, searchedValue?: string) {
			const res = await instance.get(`/users/upload-summary`, {
				params: {
					page,
					limit,
					countryId: country,
					projectId: mission,
					usernameEmailSearch: searchedValue
				}
			});
			return res.data;
		},

		async getUploadSummary(id: number) {
			const res = await instance.get(`/users/${id}/upload-summary`);
			return res.data;
		},

		async getUploads(uploadsParams: UploadsParams) {
			const res = await instance.get(`/uploads`, {
				params: uploadsParams
			});
			return res.data;
		},

		async getUploadsById(projectId: number | number) {
			const res = await instance.get(`/uploads/${projectId}`);
			return res.data;
		},

		async getLastPendingUpload(userId: number) {
			const res = await instance.get(`/uploads/${userId}/lastPending`);
			return res.data;
		},

		async getLastUploaded(userId: number) {
			const res = await instance.get(`/uploads/${userId}/lastUpload`);
			return res.data;
		},

		async getCountries() {
			const res = await instance.get(`/countries`, {
				params: {}
			});
			return res.data;
		},

		async getPhotoSessionData(sessionId: number) {
			const res = await instance.get(`/sessions/photo/${sessionId}`);

			return res.data;
		},

		async saveProject(project: Project) {
			const getFormData = (project: Project) => {
				const formData = new FormData();

				Object.keys(project).forEach(key => {
					if (key === 'newReferences') {
						project[key].forEach((value, index) => {
							const file = value.file;

							formData.append('newReferences', file, file.name);
							formData.append(`newReferencesData[${index}]`, JSON.stringify({ cardType: value.cardType, caption: value.caption, type: value.type }));
						});
					} else if (key === 'references') {
						project.references.forEach((reference, index) => {
							formData.append(`references[${index}]`, JSON.stringify(reference));
						});
					} else if (key === 'name') {
						formData.append('name', JSON.stringify(project[key]));
					} else if (key === 'privateUsers') {
						formData.append('privateUsers', JSON.stringify(project['privateUsers']));
					} else if (key === 'clientUsers') {
						formData.append('clientUsers', JSON.stringify(project[key]));
					} else if (key === 'csvFile') {
						formData.append('csvFile', project[key]);
					} else if (key === 'cards') {
						formData.append('cards', JSON.stringify(project[key]));
					} else if (key == 'selectedObjects') {
						formData.append('selectedObjects', JSON.stringify(project[key]));
					} else if (key === 'invasiveObjects') {
						formData.append('invasiveObjects', JSON.stringify(project[key]));
					} else {
						formData.append(key, project[key]);
					}
				});

				return formData;
			};

			const formData = getFormData(project);

			const res = await instance.post('/projects/saveProject', formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});

			return res;
		},
		async loadProject(projectId: number) {
			const res = await instance.get(`/projects/loadProject/${projectId}`);
			return res;
		},

		async changeInvasiveSessionStatus(sessionUploadIds, status, rejectionReasonId, customRejectionReason, changedPaymentAmount, sessionId) {
			const res = await instance.post('/invasive/change-invasive-session-status', {
				sessionUploadIds,
				status,
				rejectionReasonId,
				customRejectionReason,
				changedPaymentAmount,
				sessionId
			});
			return res;
		},
		async getTags() {
			const res = await instance.get(`/tags`, {
				params: {}
			});
			return res.data;
		},

		async disableSightingPolygon(initialSessionId: number) {
			const res = await instance.patch(`/invasive/disable-sighting-polygon`, {
				initialSessionId: initialSessionId
			});
			return res.data;
		},

		async getObjects() {
			const res = await instance.get(`/objects/all`, {
				params: {}
			});
			return res.data;
		},

		async getVehicles() {
			const res = await instance.get(`/vehicles`, {
				params: {}
			});
			return res.data;
		},

		async getUsers(selectedProject?: number | null) {
			const res = await instance.get('/users', {
				params: !!selectedProject
					? {
							projectID: selectedProject
					  }
					: {}
			});
			return res.data;
		},

		async getAllUsers(filters: GetAllUserFilters) {
			const res = await instance.get('/users/all', {
				params: { ...filters }
			});
			return res.data;
		},

		async getUsersForSelect(usersParams?: UsersParams) {
			const res = await instance.get('/users', {
				params: { usersParams }
			});
			return res.data;
		},

		async deleteUsers(userIds: number[]) {
			const res = await instance.delete('/users', {
				data: {
					IDs: userIds
				}
			});
			return res.data;
		},

		async getAllSessions() {
			const res = await instance.get('/sessions', {
				params: {}
			});
			return res.data;
		},

		async getUserSessions(userId: number) {
			const res = await instance.get(`/users/${userId}/sessions`, {
				params: {}
			});
			return res.data;
		},

		async getVideos(videoRequest: VideoRequest) {
			const res = await instance.get('/sessions/videos', {
				params: videoRequest
			});
			return res.data;
		},

		async getUpload(uploadId: number | string) {
			const res = await instance.get(`/uploads/${uploadId}`, {
				params: {}
			});
			return res.data;
		},

		async patchUploads(id: number, status: string, bodyParams = {}) {
			const res = await instance.patch(`/uploads/${id}?status=${status}`, bodyParams);
			return res;

			// if (bodyParams) {
			// 	const res = await instance.patch(`/uploads/${id}?status=${status}`, bodyParams);
			// 	return res;
			// }
			// const res = await instance.patch(`/uploads/${id}?status=${status}`);
			// return res.data;
		},

		async getUserVideos(userId: number) {
			const res = await instance.get(`/users/${userId}/sessions`, {
				params: {}
			});
			return res.data;
		},

		async getAppSettings() {
			const res = await instance.get('/app-settings/getAppSettings');

			return res.data;
		},

		async saveAppSettings(appSettings: any) {
			const res = await instance.patch('/app-settings/saveAppSettings', appSettings);

			return res.data;
		},
		async uploadVideosToVendor(videoIDs: number[]) {
			// TODO BE chnage
			const res = await instance.post(`/sessions/videos/uploadZipVideoGPX`, { videoIDs });
			return res.data;
		},

		async updateSessionDensity(sessionId: number, density: number, area: number, marker: number, projectId: number) {
			const res = await instance.patch('/invasive/update-session-density', { sessionId, density, area, projectId, marker });
			return res;
		},

		async updateSessionArea(sessionId: number, density: number, area: number, marker: number, projectId: number) {
			const res = await instance.patch('/invasive/update-session-area', { sessionId, density, area, projectId, marker });
			return res;
		},

		async validateVideo(validated: boolean, videoID: number, message: string) {
			const res = await instance.put(`/uploads/${videoID}`, {
				note: message
			});
			return res.data;
		},

		async getLeaderboard() {
			const res = await instance.get('/users/leaderboard', {
				params: {}
			});
			return res.data;
		},

		async getLeaderboardPerProjectAndCountry(selectedProjectAndCountry?: LeaderboardRequest | undefined) {
			const res = await instance.get('/projects/leaderboard', {
				params: selectedProjectAndCountry
			});
			return res.data;
		},

		async getUserPayments(startMonth: number, endMonth: number, projectId: number | null) {
			const res = await instance.get(`/users/payments?${projectId ? 'projectID=' + projectId : ''}`, {
				params: {
					startMonth,
					endMonth
				}
			});
			return res.data;
		},

		async getUserFeedbackSubmissions(params: { limit: number; offset: number }) {
			const res = await instance.get('/users/user-feedback-submissions', {
				params: {
					...params
				}
			});
			return res.data;
		},
		async getPaymentsByUserId(params) {
			const res = await instance.get(`/users/${params?.userId}/payments`, {
				params
			});
			return res.data;
		},

		async getSingleUserPayments(ID: number, startMonth: number, endMonth: number, projectId: number | null) {
			const res = await instance.get(`/users/payments/${ID}?${projectId ? 'projectID' + projectId : ''}`, {
				params: {
					startMonth,
					endMonth
				}
			});
			return res.data;
		},

		async setUserPayments(IDs: number[], startMonth: number, endMonth: number) {
			const res = await instance.put('/users/payments', {
				IDs,
				startMonth,
				endMonth
			});
			return res.data;
		},

		async getProjectCollectablesSummary(projectId: number) {
			const res = await instance.get(`/projects/${projectId}/collectables-summary`, {
				params: {}
			});
			return res.data;
		},

		async getProjectCollectables(projectId: number) {
			const res = await instance.get(`/projects/${projectId}/collectables`, {
				params: {}
			});
			return res.data;
		},

		async deleteUncollectedObjects(projectId: number) {
			const res = await instance.delete(`/projects/${projectId}/uncollected`, {
				params: {}
			});
			return res.data;
		},

		async uploadCollectables(file: any, projectID: number) {
			const res = await instance.post(`/upload/collectables/csv?projectID=${projectID}`, file, {
				params: {}
			});
			return res.data;
		},

		async getGPX(videoID: number) {
			const res = await instance.get(`sessions/videos/${videoID}/gpx`, { params: {} });
			return res.data;
		},

		async getWaypoints({ projectID, videoIDs, offset, limit, startDate, endDate, status }) {
			const params: any = {};

			params.offset = offset ? offset : 0;
			params.limit = limit ? limit : 10;
			params.videoIDs = videoIDs;

			if (projectID) {
				params.projectID = projectID;
			}

			if (startDate) {
				params.startDate = startDate;
			}

			if (endDate) {
				params.endDate = endDate;
			}

			if (status) {
				params.status = status;
			}

			try {
				const res = await instance.get(`sessions/videos/export/waypoints`, {
					params
				});
				return res.data;
			} catch (error) {
				return false;
			}
		},
		async getInvasiveSpeciesSessionData(sessionId: number) {
			const res = await instance.get(`invasive/load-invasive-session/${sessionId}`);

			return res.data;
		},
		async getProjectWaypoints(projectId: number) {
			const res = await instance.get(`projects/getProjectWaypoints/${projectId}`);

			return res.data;
		},
		async getWaypointsForProject(projectID: number, offset?: number, limit?: number, startDate?: number, endDate?: number, status?: number) {
			const res = await instance.get(`sessions/videos/export/waypoints?projectID=${projectID}`, {
				params: {
					offset: offset ? offset : 0,
					limit: limit ? limit : 1000,
					startDate: startDate,
					endDate: endDate,
					status
				}
			});
			return res.data;
		},

		async getExportedPhotos(photoIDs: number[]) {
			const res = await instance.get(`sessions/photos/export/photos?photoIDs=[${photoIDs}]`, {
				params: { limit: 1000, offset: 0 },
				responseType: 'arraybuffer'
			});
			return res.data;
		},

		async getExportedCoordinates(photoIDs: number[]) {
			const res = await instance.get(`sessions/photos/export/coordinates?photoIDs=[${photoIDs}]`, {
				params: { limit: 1000, offset: 0 },
				responseType: 'arraybuffer'
			});
			return res.data;
		},

		async getExportedVideos(videoIDs: number[]) {
			const res = await instance.get(`sessions/videos/export/videos?videoIDs=[${videoIDs}]`, {
				params: {},
				responseType: 'arraybuffer'
			});
			return res.data;
		},

		async getExportedVideo(videoID: number) {
			const res = await instance.get(`sessions/videos/export/videos?videoIDs=${videoID}`, {
				params: {}
			});
			return res.data;
		},

		async getPhotoExportMarkers(params: PhotoExportMarkersI) {
			const res = await instance.get('sessions/photos/export/markers', {
				params
			});
			return res.data;
		},

		async getVideoFile(videoURL: string) {
			const res = await instance.get(videoURL, { params: {}, responseType: 'blob' });
			return res.data;
		},

		async getProjectList(projectRequest?: ProjectRequest | null) {
			const res = await instance.get('/projects', {
				params: projectRequest
			});
			return res.data;
		},

		async getPayments(paymentsParams: PaymentsParams) {
			const res = await instance.get(`/payments`, {
				params: paymentsParams
			});
			return res.data;
		},

		async getProject(projectId: number, language: string = 'en') {
			const res = await instance.get(`/projects/${projectId}/`, {
				params: {},
				headers: getHeaders(language)
			});

			return res.data;
		},

		async getStateList() {
			const res = await instance.get('/projects/countries', {
				params: {}
			});
			return res.data;
		},

		async sendNotifications(data: NotificationBroadcast) {
			const res = await instance.post('/users/notifications/broadcast', data);
			return res.data;
		},
		async saveCustomProject(mission: any) {
			const getFormData: any = (object: any) => {
				const initialFormData = new FormData();

				return Object.keys(object).reduce((formData, key) => {
					if (key === 'geoJSON') {
						// formData.append('geoJSON', object[key][0]); // if error add 1 more par object[key][0].path
					} else if (key === 'objects') {
						object[key].forEach((value: any) => {
							formData.append(
								'objects[]',
								JSON.stringify({
									ID: value.ID,
									value: value.value
								})
							);
						});
					} else if (key == 'references') {
						object[key].forEach((value: any) => {
							formData.append(
								'references',
								new File(value.file, value.caption, {
									type: value.file[0].type,
									lastModified: value.file[0].lastModified
								})
							);
						});
					} else if (key === 'oldReferences') {
						object[key].forEach((value: any) => {
							formData.append(
								'oldReferences[]',
								JSON.stringify({
									URL: value.URL,
									caption: value.caption
								})
							);
						});
					} else if (key === 'users') {
						object[key].forEach((value: string) => {
							formData.append('users[]', value);
						});
					} else if (key === 'tags') {
						object[key].forEach((value: string) => {
							formData.append('tags[]', value);
						});
					} else if (key === 'steps') {
						object[key].forEach((value: any) => {
							formData.append('steps[]', JSON.stringify(value));
						});
					} else if (key === 'stepsToDelete') {
						object[key].forEach((value: any) => {
							formData.append('stepsToDelete[]', JSON.stringify(value));
						});
					} else if (key === 'csvFile') {
						formData.append('csvFile', object[key]);
					} else if (key === 'clientUsers') {
						object[key].forEach((value: string) => {
							formData.append('clientUsers[]', value);
						});
					} else {
						formData.append(key, object[key]);
					}

					return formData;
				}, initialFormData);
			};
			const formData = getFormData(mission);

			// If mission has id 0 create a new one
			if (mission.id === 0) {
				const res = await instance.post('/projects/custom', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				return res;
			} else if (mission.id > 0) {
				// Otherwise edit it
				const res = await instance.patch(`/projects/custom/${mission.id}`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				return res;
			}
		},
		async createProject(data: AddProject) {
			const getFormData: any = (object: any) =>
				Object.keys(object).reduce((formData, key) => {
					if (key === 'CSVFile') {
						formData.append('CSVFile', object[key][0]); // if error add 1 more par object[key][0].path
					} else if (key === 'tags') {
						object[key].forEach((value: string) => {
							formData.append('tags[]', value);
						});
					} else if (key === 'users') {
						object[key].forEach((value: string) => {
							formData.append('users[]', value);
						});
					} else if (key === 'clientUsers') {
						object[key].forEach((value: string) => {
							formData.append('clientUsers[]', value);
						});
					} else {
						formData.append(key, object[key]);
					}
					return formData;
				}, new FormData());

			const formData = getFormData(data);
			const res = await instance.post('/projects', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return res.data;
		},

		async createPhotoProject(data: AddPhotoProject) {
			const getFormData: any = (object: any) => {
				const initialFormData = new FormData();
				return Object.keys(object).reduce((formData, key) => {
					if (key === 'geoJSON') {
						//formData.append('geoJSON', object[key][0]); // if error add 1 more par object[key][0].path
					} else if (key === 'objects') {
						object[key].forEach((value: any) => {
							formData.append(
								'objects[]',
								JSON.stringify({
									ID: value.ID,
									value: value.value
								})
							);
						});
					} else if (key === 'referenceImages') {
						object[key].forEach((value: any) => {
							formData.append(
								'referenceImages',
								new File(value.file, value.caption, {
									type: value.file.type,
									lastModified: value.file.lastModified
								})
							);
						});
					} else if (key === 'users') {
						object[key].forEach((value: string) => {
							formData.append('users[]', value);
						});
					} else if (key === 'tags') {
						object[key].forEach((value: string) => {
							formData.append('tags[]', value);
						});
					} else if (key === 'clientUsers') {
						object[key].forEach((value: string) => {
							formData.append('clientUsers[]', value);
						});
					} else {
						formData.append(key, object[key]);
					}
					return formData;
				}, initialFormData);
			};

			const formData = getFormData(data);
			const res = await instance.post('/projects/photo', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return res.data;
		},

		async deleteCollectables(csvFileIDs: number[]) {
			const res = await instance.delete('/upload/csv/delete', {
				data: {
					csvFileIDs
				}
			});
			return res.data;
		},

		async deleteAllCollectables() {
			const res = await instance.delete(`/collectables`, {
				params: {}
			});
			return res.data;
		},

		async deleteSelectedPaymentsRows(paymentIds) {
			const res = await instance.delete(`/payments`, {
				data: {
					ids: paymentIds
				}
			});
			return res.data;
		},

		async patchMarkAsPaid(paymentIds) {
			const res = await instance.patch(`/payments?status=paid`, {
				ids: paymentIds
			});
			return res.data;
		},

		async deleteProject(projectId: number) {
			const res = await instance.delete(`/projects/${projectId}/`, {
				params: {}
			});
			return res.data;
		},

		async getCollectablesList(projectId: number) {
			const res = await instance.get(`/upload/csv/getByProjectID/${!!projectId || projectId === 0 ? projectId : ''}`, {
				params: {}
			});
			return res.data;
		},

		async getGeojson(videoIDs: number[]) {
			const res = await instance.get(`/sessions/videos/export/getGeoJSON`, {
				params: {
					videoIDs: videoIDs
				}
			});
			return res.data;
		},

		async uploadGeojson(file: any) {
			const res = await instance.post(`/sessions/uploadGeoJSONFile`, file, {
				params: {}
			});
			return res.data;
		},

		async getRejectionReasons() {
			const res = await instance.get(`rejection-reasons`);
			return res.data;
		},

		async getUploadCollectables(uploadId) {
			const res = await instance.get(`/uploads/${uploadId}/collected-summary`);
			return res.data;
		},

		async editProject(data: EditProject, projectId: number) {
			const getFormData: any = (object: any) =>
				Object.keys(object).reduce((formData, key) => {
					if (key === 'CSVFile') {
						formData.append('CSVFile', object[key][0]); // if error add 1 more par object[key][0].path
					} else if (key === 'tags') {
						object[key].forEach((value: any) => {
							formData.append('tags[]', value);
						});
					} else if (key === 'users') {
						object[key].forEach((value: any) => {
							formData.append('users[]', value);
						});
					} else if (key === 'clientUsers') {
						object[key].forEach((value: any) => {
							formData.append('clientUsers[]', value);
						});
					} else {
						formData.append(key, object[key]);
					}
					return formData;
				}, new FormData());

			const formData = getFormData(data);
			const res = await instance.put(`/projects/${projectId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return res.data;
		},

		async exportInvasiveUploads(projectId: number) {
			const res = await instance.get(`/invasive/exportUploadsData/${projectId}`);

			return res.data;
		},
		async editPhotoProject(data: EditProject, projectId: number) {
			const getFormData: any = (object: any) => {
				const initialFormData = new FormData();
				return Object.keys(object).reduce((formData, key) => {
					if (key === 'geoJSON') {
						//formData.append('geoJSON', object[key][0]); // if error add 1 more par object[key][0].path
					} else if (key === 'objects') {
						object[key].forEach((value: any) => {
							formData.append(
								'objects[]',
								JSON.stringify({
									ID: value.ID,
									value: value.value
								})
							);
						});
					} else if (key === 'users') {
						object[key] &&
							object[key].forEach((value: any) => {
								formData.append('users[]', value);
							});
					} else if (key === 'referenceImages') {
						object[key].forEach((value: any) => {
							formData.append(
								'referenceImages',
								new File(value.file, value.caption, {
									type: value.file.type,
									lastModified: value.file.lastModified
								})
							);
						});
					} else if (key === 'oldReferenceImages') {
						object[key].forEach((value: any) => {
							formData.append(
								'oldReferenceImages[]',
								JSON.stringify({
									URL: value.URL,
									caption: value.caption
								})
							);
						});
					} else if (key === 'tags') {
						object[key].forEach((value: any) => {
							formData.append('tags[]', value);
						});
					} else if (key === 'clientUsers') {
						object[key] &&
							object[key].forEach((value: any) => {
								formData.append('clientUsers[]', value);
							});
					} else {
						formData.append(key, object[key]);
					}
					return formData;
				}, initialFormData);
			};

			const formData = getFormData(data);
			const res = await instance.put(`/projects/photo/${projectId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return res.data;
		},

		async patchFinishProjectStatus(projectId) {
			const res = await instance.patch(`/projects/${projectId}?status=finished`);
			return res.data;
		},

		async pauseProject(projectId: number) {
			const res = await instance.patch(`/projects/${projectId}?status=paused`);
			return res.data;
		},

		async resumeProject(projectId: number) {
			const res = await instance.patch(`/projects/${projectId}?status=active`);
			return res.data;
		},

		async getUsersCSV(userIds) {
			const res = await instance.get(`/users/csv`, {
				params: {
					ids: userIds
				}
			});
			return res.data;
		},

		async getPaymentsCSV(paymentIds) {
			const res = await instance.get(`/payments/csv`, {
				params: {
					ids: paymentIds
				}
			});
			return res.data;
		},

		async getTxt(objectID: number) {
			const res = await instance.get(`/sessions/photos/${objectID}/txt`);
			return res.data;
		},

		async returnObjectsToMap(uploadId: number) {
			const res = await instance.post(`/uploads/${uploadId}/returnObjectsToMap`);

			return res.data;
		},

		async syncMarkers() {
			return instance.post('/projects/syncmarkers');
		},

		async syncCollectables(projectID: number) {
			return instance.post(`/projects/${projectID}/synccollectables`);
		},

		async syncAllCollectables() {
			return instance.post('/projects/syncallcollectables');
		},

		async syncPhotoMarkers(projectID: number) {
			return instance.post(`/projects/${projectID}/syncphotomarkers`);
		},

		async syncAllPhotoMarkers() {
			return instance.post('/projects/syncallphotomarkers');
		},

		async testSessionValue(projectId: number, density: number, marker: number, area: number) {
			return instance.post('/invasive/test-session-value', {
				projectId,
				density,
				marker,
				area
			});
		},

		async getUserTransactions(userId: number) {
			const res = await instance.get(`/transactions/usertransactions/${userId}`);
			return res.data;
		},
		async getTransactionEvents(transactionId: number, userId: number) {
			const res = await instance.get(`/transactions/events`, {
				params: {
					transactionId,
					userId
				}
			});
			return res.data;
		},
		async modifyUserBalance(amount: number, info: string, userId: number) {
			const res = await instance.post(`/transactions/modifybalance`, {
				userId,
				amount,
				info
			});
			return res.data;
		}
	};
}
