export const ROUTES = {
	passwordReset: `${process.env.PUBLIC_URL}/passwordReset/:token`,
	passwordRequest: `${process.env.PUBLIC_URL}/passwordRequest`,
	lobbyWebView: `${process.env.PUBLIC_URL}/lobbyWebView`,
	login: `${process.env.PUBLIC_URL}/login`,
	home: `${process.env.PUBLIC_URL}/`,
	sessions: `${process.env.PUBLIC_URL}/sessions`,
	userSessions: `${process.env.PUBLIC_URL}/user-sessions/:userId`,
	createUserSessionsUrl(userId: number) {
		return `${process.env.PUBLIC_URL}/user-sessions/${userId}`;
	},
	uploads: `${process.env.PUBLIC_URL}/uploads`,
	sessionVideos: `${process.env.PUBLIC_URL}/session-videos/:sessionId`,
	createVideosUrl(sessionId: number) {
		return `${process.env.PUBLIC_URL}/session-videos/${sessionId}`;
	},
	video: `${process.env.PUBLIC_URL}/video/:videoId`,
	createVideoUrl(videoId: number) {
		return `${process.env.PUBLIC_URL}/video/${videoId}`;
	},

	image: `${process.env.PUBLIC_URL}/image/:imageId`,
	createImageUrl(imageId: number) {
		return `${process.env.PUBLIC_URL}/image/${imageId}`;
	},

	users: `${process.env.PUBLIC_URL}/users`,
	user: `${process.env.PUBLIC_URL}/profile/:userId`,
	userPayments: `${process.env.PUBLIC_URL}/userPayments/:userId`,
	createUsersPaymentsUrl(userId: number) {
		return `${process.env.PUBLIC_URL}/userPayments/${userId}`;
	},
	createUsersInfoUrl(userId: number) {
		return `${process.env.PUBLIC_URL}/profile/${userId}`;
	},
	projects: `${process.env.PUBLIC_URL}/projects`,
	project: `${process.env.PUBLIC_URL}/project/:projectId`,
	createProjectsInfoUrl(projectId: number) {
		return `${process.env.PUBLIC_URL}/project/${projectId}`;
	},
	EditProjectsInfoUrl(projectId: number) {
		return `${process.env.PUBLIC_URL}/edit-project/${projectId}`;
	},
	addProject: `${process.env.PUBLIC_URL}/add-project`,
	missionEditor: `${process.env.PUBLIC_URL}/mission-editor/:projectId`,
	invasiveSessionViewer: `${process.env.PUBLIC_URL}/invasive-session-viewer/:sessionId`,
	projectEditor: `${process.env.PUBLIC_URL}/project-editor/:projectId`,
	editProject(projectId: number) {
		return `${process.env.PUBLIC_URL}/project-editor/${projectId}`;
	},
	customMissionEdit(projectId: number) {
		return `${process.env.PUBLIC_URL}/mission-editor/${projectId}`;
	},
	SeeProjectsInfoUrl(projectId: number) {
		return `${process.env.PUBLIC_URL}/see-mission/${projectId}`;
	},
	seeProject: `${process.env.PUBLIC_URL}/see-mission/:projectId`,
	leaderboard: `${process.env.PUBLIC_URL}/leaderboard`,
	payments: `${process.env.PUBLIC_URL}/payments`,
	coinUpload: `${process.env.PUBLIC_URL}/coin-upload`,
	overview: `${process.env.PUBLIC_URL}/overview`,
	admins: `${process.env.PUBLIC_URL}/admins`,
	appSettings: `${process.env.PUBLIC_URL}/app-settings`,
	userFeedback: `${process.env.PUBLIC_URL}/user-feedback`,
	notifications: `${process.env.PUBLIC_URL}/notifications`,
	globalmap: `${process.env.PUBLIC_URL}/global-map`,
	projectMap: `${process.env.PUBLIC_URL}/project-map`,
	dashboard: `${process.env.PUBLIC_URL}/dashboard`
};
